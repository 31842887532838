// header.js
import React, { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { Link } from "react-router-dom";
import { useConnectWallet } from '@web3-onboard/react';
import { Wallet } from '../onboard/onboard';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Helmet } from "react-helmet";

const Header = () => {
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const { account } = useEthers();
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('us');

    const handleCurrencySelect = (currency) => {
        setSelectedCurrency(currency);
        localStorage.setItem('selectedCurrency', currency); // Save selected currency to localStorage
        window.location.reload();
    };

    useEffect(() => {
        const selectedCurrencyLocalStorage = localStorage.getItem('selectedCurrency');
        if (selectedCurrencyLocalStorage) {
            setSelectedCurrency(selectedCurrencyLocalStorage);
        }
    }, []);

    const BlockedUserToast = () => (
        <div>
            <Link to="https://discord.gg/MMUJR3PFNE" target="_blank">You have been blocked from accessing Timmy Finance, Join our discord to appeal.</Link>
        </div>
    );

    useEffect(async () => {
        if (wallet) {
            const checkBlock = await axios.get(`https://stakingapi.timmy.finance/settings/blocked?wallet=${wallet.accounts[0]?.address}`)
            if (checkBlock.data['exists']) {
                toast.error(BlockedUserToast);
                await disconnect(wallet);
                localStorage.removeItem('connectedWallet');
                return;
            }
            if (
                wallet?.accounts[0]?.address.toLowerCase() ===
                '0xBFf06DEb7059B475a734E71D101b2D9fF3247cFF'.toLowerCase()
            ) {
                setIsAdmin(true);
            }
        } else {
            setIsAdmin(false);
        }
    }, [wallet]);

    return (
        <>
            <style>
                {`
                .shiny-box, .coming-soon {
                    display: inline-block;
                    padding: 2px 8px;
                    background: linear-gradient(45deg, #f2c94c, #f39c12, #e67e22, #d35400);
                    border-radius: 12px;
                    color: white;
                    font-weight: bold;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    margin-left: 5px;
                    animation: shine 1.5s infinite alternate;
                }

                .new-symbol {
                    display: inline-block;
                    background-color: #2ecc71;
                    color: white;
                    padding: 2px 8px;
                    border-radius: 12px;
                    font-weight: bold;
                    margin-left: 5px;
                }

                @keyframes shine {
                    from {
                        background-position: -200% center;
                    }
                    to {
                        background-position: 200% center;
                    }
                }
                `}
            </style>
            <header id="header">
                <nav
                    data-aos="zoom-out"
                    data-aos-delay={800}
                    className="navbar gameon-navbar navbar-expand"
                >
                    <div className="container header">
                        <Link to="/" className="navbar-brand">
                            <img src="https://imgur.com/KzUjtzC.gif" alt="Timmy Finance"/>
                        </Link>
                        <div className="ml-auto"/>
                        <ul className="navbar-nav items mx-auto">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link">
                                    Projects <i className="icon-arrow-down"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <a href="/degen-donkeys" className="nav-link">
                                            Degen Donkeys V2 <span className="new-symbol">New</span>
                                        </a>
                                        <a href="/scrappies" className="nav-link">
                                            Scrap Monsters
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link">
                                    Arcade <i className="icon-arrow-down"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <a href="/coinflip" className="nav-link">
                                            Timmy flip <span className="new-symbol">New</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link">
                                    NFT Staking <i className="icon-arrow-down"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <a href="https://stake.timmy.finance" className="nav-link" target="_blank"
                                           rel="noopener noreferrer">
                                            Timmy Finance Staking
                                        </a>
                                        <a href="https://staking.timmy.finance" className="nav-link" target="_blank"
                                           rel="noopener noreferrer">
                                            V2 Staking <span className="new-symbol">New</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul className="navbar-nav toggle">
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    className="nav-link"
                                    data-toggle="modal"
                                    data-target="#menu"
                                >
                                    <i className="icon-menu m-0"/>
                                </Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav action">
                            <Wallet/>
                        </ul>
                    </div>
                </nav>
            </header>
            <ToastContainer />
        </>
    );
};

export default Header;
