import React, { useEffect, useRef, useState } from 'react';
import winSound from '../common/coinflip/audio/win.mp3';
import loseSound from '../common/coinflip/audio/lose.mp3';
import '../common/coinflip/cf.css'

const Popup = ({ outcome, onClose }) => {
    const popupRef = useRef(null);
    const [timeLeft, setTimeLeft] = useState(10); // Total time left in seconds

    useEffect(() => {
        // Play sound based on outcome
        if (outcome === 'win') {
            new Audio(winSound).play();
        } else {
            new Audio(loseSound).play();
        }

        // Countdown timer to close the popup
        const interval = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        // Close the popup after 10 seconds
        const timeout = setTimeout(() => {
            onClose();
        }, 10000);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [outcome, onClose]);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const popupStyle = {
        position: 'fixed',
        zIndex: '1000',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: outcome === 'win' ? 'green' : 'red',
        color: 'white',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        animation: 'fadeInOut 10s forwards',
        transition: 'opacity 2s ease-in-out', // Add a transition effect for fading
        opacity: outcome ? 1 : 0, // Start with opacity 0 if outcome is not set
    };

    return (
        <div ref={popupRef} style={popupStyle}>
            <h2>{outcome === 'win' ? 'Congratulations! You won!' : 'Sorry! You lost!'}</h2>
            <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${(timeLeft / 10) * 100}%` }}></div>
            </div>
        </div>
    );
};

export default Popup;