import React from 'react';
import './CoinflipCard.css'; // Import local CSS file

const CoinflipCard = ({ coinflip }) => {
    const isWin = coinflip.result === coinflip.selected;

    const cardStyle = {
        backgroundColor: isWin ? 'green' : 'red',
        color: 'white',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        textAlign: 'center'
    };

    return (
        <div style={cardStyle}>
            <p>
                <strong>Player: </strong>
                <a href={`https://cronoscan.com/address/${coinflip.player}`} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>
                    {coinflip.player}
                </a>
            </p>
            <p><strong>Selected Side:</strong> {coinflip.selected}</p>
            <p><strong>Result:</strong> {coinflip.result}</p>
            {coinflip.totalWon !== undefined && (
                <p><strong>Total won:</strong> {coinflip.totalWon} CRO</p>
            )}
            <p><strong>Time:</strong> {new Date(coinflip.createdAt).toLocaleString()}</p>
        </div>
    );

};

export default CoinflipCard;
