import React, {useEffect, useState} from 'react';
import {useContractFunction} from '@usedapp/core';
import { Contract, ethers, utils } from 'ethers';

import {ChainId, useEthers } from '@usedapp/core';
import {init, useConnectWallet, useSetChain} from '@web3-onboard/react';
import {toast} from 'react-toastify';
import web3 from 'web3'

import {
    useGetMaxSupply,
    useGetTotalSupply,
    useGetCurrentPrice, useCheckActiveSale
} from '../../utilities/Web3/contract';



// import CONTRCT_ABI from '../../abi/LimitedERC721A.json';
import CONTRCT_ABI from '../../abi/updatedAbi.json';
import {CONTRACT_ADDRESS} from '../../utilities/Constants/index';
import CroToUsdConverter from "../../utilities/priceConverter";

import {stakeNFT} from "../common/stakingRedirect"


const initData = {
    img: '/img/donkey.gif',
    title: 'Degen Donkeys V2',
    reg_date: 'ee',
    raise: '222.5k',
    val: '$13350',
    allocation: '$0',
    progress: '0%',
    mecha: '50  SUPPLY',
    busd: '350 CRO',
    actionBtn: 'Mint NFT',
    actionBtnFMint: 'reserve (ADMIN)',
    video_img: '/img/donkey.gif',
    video_icon: 'fa-solid fa-play',
    video_link: 'https://youtu.be/ki9TdF60FQY',
    project_admin: '0xBFf06DEb7059B475a734E71D101b2D9fF3247cFF',
    actionBtnPauseMints: 'Pause mints (ADMIN)',
    actionBtnWithdraw: 'Withdraw funds (ADMIN)',
    staking: false,
    stakeLink: "https://stake.timmy.finance/0x58fFbD0C8B04C523EC12003D01cb1e067471375e"
};

const summaryData = {
    title: 'Project Summary',
    content_1:
        'Welcome to the exhilarating world of Degen Donkeys V2, the official remake of the beloved Timmy Finance classic! Prepare to be captivated by stunning new artwork that brings these digital treasures to life like never before. With an increased supply of 50, there\'s plenty of opportunity to add these unique assets to your collection. Plus, we\'ve heard your feedback and boosted the max wallet limit to 4, providing you with even more space to store and showcase your prized donkeys. But that\'s not all – stay tuned for the exciting utility announcement, coming soon! In the meantime, the pulse-pounding raffle action from the previous collection continues, offering you the chance to win big while enjoying the thrill of the chase. Join us in the next chapter of the Degen Donkeys saga and experience the ultimate fusion of art, utility, and community excitement!'
};

// const overviewData = {
//   title: 'Project Overview',
//   list_heading: 'Utilities',
//   content:
//     'Introducing the Rebel Kangas, a group of blockchain vigilantes who call out fraudulent activity and refuse to tolerate bad behavior. Despite being labeled as the bad guys, they strive to be a force for good in the crypto community.'
// };
//
// const tokenmetricsData = {
//   title: 'Distributions',
//   content: 'See below full breakdown'
// };
//
// const roadmapData = {
//   title: 'Roadmap',
//   content_1:
//     'In summary, our plan is to construct a decentralized launchpad where profits are distributed among holders. Additionally, we will implement a referral program where rewards can be earned by promoting our project. We also aim to expand our portfolio of game of chance products, such as Lotto and Coin Flip, as well as establish staking pools. Finally, we will launch our token and utilize any remaining funds from minting for liquidity. ',
//   content_2: (
//     <>
//       Full roadmap here:{' '}
//       <a href="https://roo-finance.gitbook.io/roadmap./">
//         https://roo-finance.gitbook.io/roadmap./
//       </a>
//     </>
//   )
// };

const socialData = [
    {
        id: '1',
        link: 'https://twitter.com/Donkey__Finance',
        icon: 'fab fa-twitter'
    },
    {
        id: '2',
        link: 'https://discord.gg/KDRYAG37U5',
        icon: 'fab fa-discord'
    }
];

const overviewList = [
    {
        id: '1',
        content:
            'Owning a Rebel Kanga NFT allows for a share of 80% of ecosystem profits. '
    },
    {
        id: '2',
        content:
            'A portion of royalties (6%) will be allocated towards repurchasing tokens and replenishing the staking pool. '
    },
    {
        id: '3',
        content:
            'The majority (75%) of funds raised through minting will go towards development, while the remaining 25% will be used for token liquidity, with none going towards personal gain for the project owner. '
    },
    {
        id: '4',
        content:
            'The Rebel Kangas are known for their playful and mischievous nature, as they use comics to convey their message and keep a lookout for any suspicious activity.'
    }
];

const tokenList = [
    {
        id: '1',
        title: 'Raise',
        content: '222.5k Cro'
    },
    {
        id: '2',
        title: 'Distribution',
        content:
            '65% towards development, 25% token liquidity and 10% Marketing. 0% Owner.'
    },
    {
        id: '3',
        title: 'NFT Distribution',
        content: "2500 NFT's Public Mint / 300 NFT's to Owner/Team"
    },
    {
        id: '4',
        title: 'Doxxed?',
        content: 'Yes, with CroArmy'
    }
];

const ProjectSingle = () => {
    const ContractInterface = new utils.Interface(CONTRCT_ABI);
    const RBContract = new Contract(CONTRACT_ADDRESS, ContractInterface);


    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [isAdmin, setIsAdmin] = useState(false);
    const [{ connectedChain, chains, settingChain }, setChain] = useSetChain();
    const [disabled, setDisabled] = useState(false);
    const [selectedCount, setSelectedCount] = useState(1);
    const [correctChain, setCorrectChain] = useState(false);
    const {activateBrowserWallet, account, activate, deactivate} = useEthers();
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const {currentPrice} = useGetCurrentPrice();
    const {maxSupply} = useGetMaxSupply();
    const {totalSupply} = useGetTotalSupply();
    const {activeSale} = useCheckActiveSale();

    const desiredChainId = ChainId.Cronos;
    // Force user to change chain to a specific chain ID (e.g., ChainId.Cronos)
    useEffect( async () => {
        if (connectedChain?.id !== "0x19") {
            await setChain({chainId: desiredChainId});
            setDisabled(true)
        } else {
            setCorrectChain(true)
        }
    }, [connectedChain, setChain, ChainId, desiredChainId])


    // const feeData = wallet?.provider.getFeeData();

    // const etherProvider = () => {
    //     try {
    //         return new ethers.providers.Web3Provider(wallet?.provider);
    //     } catch (error) {}
    // };
    // const etherSigner = etherProvider()?.getSigner();
    // const contractInstance = new web3.eth.Contract(CONTRCT_ABI);
    // contractInstance.options.address = CONTRACT_ADDRESS.toLowerCase();



    // transaction instance
    const {state, send} = useContractFunction(RBContract, 'mint', {
        transactionName: 'Mint'
    });

    const {state: stateFreemint, send: sendFreeMint} = useContractFunction(RBContract, 'reserve', {
        transactionName: 'reserve'
    });

    const {state: statePauseMints, send: sendPauseMints} = useContractFunction(RBContract, 'setSaleIsActive', {
        transactionName: 'pause mints'
    });

    const {state: stateWithdraw, send: sendWithdraw} = useContractFunction(RBContract, 'withdraw', {
        transactionName: 'withdraw'
    });


    useEffect(() => {
        const address = wallet?.accounts[0]?.address; // Add null checks for safety
        const checkWalletStatus = async () => {
            if (address) {
                // Set admin status
                setIsAdmin(initData.project_admin.toLowerCase().includes(address.toLowerCase()));
            } else {
                setIsAdmin(false);
            }

        };
        checkWalletStatus();
    }, [wallet]); // Add wallet as a dependency


    useEffect(() => {
        if (stateWithdraw.status === 'PendingSignature') {
            toast.warning('Withdraw in progress... Please wait.', {autoClose: 6000});
        }
        if (stateWithdraw.status === 'Success') {
            setDisabled(false);
            setSelectedCount(1);
            toast.success('Congratulations! Withdraw Successful.', {autoClose: 6000});
        }
        if (stateWithdraw.status === 'Exception') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(stateFreemint.errorMessage, {autoClose: 6000});
        }
        if (stateWithdraw.status === 'Fail') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(stateFreemint.errorMessage, {autoClose: 6000});
        }

        delete stateWithdraw['status'];
    }, [stateWithdraw]);


    useEffect(() => {
        if (statePauseMints.status === 'PendingSignature') {
            if (activeSale) {
                toast.warning('Mint pausing in progress... Please wait.', {autoClose: 6000});
            } else {
                toast.warning('Mint unpausing in progress... Please wait.', {autoClose: 6000});
            }
        }
        if (statePauseMints.status === 'Success') {
            setDisabled(false);
            setSelectedCount(1);
            toast.success('Successfully changed minting status!', {autoClose: 6000});
        }
        if (statePauseMints.status === 'Exception') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(statePauseMints.errorMessage, {autoClose: 6000});
        }
        if (statePauseMints.status === 'Fail') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(statePauseMints.errorMessage, {autoClose: 6000});
        }

        delete statePauseMints['status'];
    }, [statePauseMints]);

    useEffect(() => {
        if (stateFreemint.status === 'PendingSignature') {
            toast.warning('Free Minting in progress... Please wait.', {autoClose: 6000});
        }
        if (stateFreemint.status === 'Success') {
            setDisabled(false);
            setSelectedCount(1);
            toast.success('Congratulations! Free Mint Successful.', {autoClose: 6000});
        }
        if (stateFreemint.status === 'Exception') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(stateFreemint.errorMessage, {autoClose: 6000});
        }
        if (stateFreemint.status === 'Fail') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(stateFreemint.errorMessage, {autoClose: 6000});
        }

        delete stateFreemint['status'];
    }, [stateFreemint]);

    useEffect(() => {
        if (state.status === 'PendingSignature') {
            toast.warning('Minting in progress... Please wait.', {autoClose: 6000});
        }
        if (state.status === 'Success') {
            setDisabled(false);
            setSelectedCount(1);
            toast.success('Congratulations! Mint Successful.', {autoClose: 6000});
        }
        if (state.status === 'Exception') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(state.errorMessage, {autoClose: 6000});
        }
        if (state.status === 'Fail') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(state.errorMessage, {autoClose: 6000});
        }

        delete state['status'];
    }, [state]);

    useEffect(() => {
        const selectedCurrencyStorage = localStorage.getItem('selectedCurrency')
        if (selectedCurrencyStorage) {
            setSelectedCurrency(selectedCurrencyStorage);
        }
    }, []);

    const changeMintCount = (count) => {
        if (correctChain) {
            setSelectedCount(count);
        }

    };

    async function pauseMints() {
        if (!account) {
            activateBrowserWallet();
            return;
        }
        if (!isAdmin) return;
        try {
            if (correctChain) {
                if (activeSale) {
                    sendPauseMints(false)
                } else {
                    sendPauseMints(true)
                }
            }
        } catch (error) {
            toast.error(error?.message);
            setDisabled(false);
            setSelectedCount(1);
        }
    }

    async function mintNFT() {
        if (disabled) return;
        if (!activeSale) {
            toast.error('Mints are currently paused.');
            return;
        }
        if (!account) {
            activateBrowserWallet();
            return;
        }
        try {
            if (correctChain) {
                const amount = parseInt(selectedCount); // 1 NFT per transaction can user mint

                const totalCost = (currentPrice * selectedCount).toString(); // Convert to string

                send(amount, {
                    value: utils.parseEther(totalCost),
                    gasLimit: utils.parseUnits('190000', 'wei'),
                });
            }

        } catch (error) {
            toast.error(error?.message);
            setDisabled(false);
            setSelectedCount(1);
        }
    }

    async function freeMintNFT() {
        if (!account) {
            activateBrowserWallet();
            return;
        }
        if (!isAdmin) return;
        try {
            if (correctChain) {
                const address = wallet?.accounts[0].address;
                const amount = parseInt(selectedCount); // 1 NFT per transaction can user mint


                sendFreeMint(address, amount, {
                    gasLimit: utils.parseUnits('900000', 'wei'),
                });
            }
        } catch (error) {
            toast.error(error?.message);
            setDisabled(false);
            setSelectedCount(1);
        }
    }

    async function withdrawFunds() {
        if (!account) {
            activateBrowserWallet();
            return;
        }
        if (!isAdmin) return;
        try {
            if (correctChain) {
                await sendWithdraw({
                    gasLimit: utils.parseUnits('96000', 'wei'),
                });
            }
        } catch (error) {
            toast.error(error?.message);
            setDisabled(false);
            setSelectedCount(1);
        }
    }

    function floatToStr(num) {
        return num.toString().indexOf('.') === -1 ? num.toFixed(1) : num.toString();
    }

    return (
        <section className="item-details-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-5">
                        {/* Project Card */}
                        <div className="card project-card no-hover">
                            <div className="media">
                                <img
                                    className="card-img-top avatar-max-lg"
                                    src={initData.img}
                                    alt="img"
                                />
                                <div className="media-body ml-4">
                                    <h4 className="m-0">{initData.title}</h4>
                                    {/*<div className="countdown-times">*/}
                                    {/*  <h6 className="my-2">Launched:</h6>*/}
                                    {/*  <div*/}
                                    {/*    className="countdown d-flex"*/}
                                    {/*    data-date={initData.reg_date}*/}
                                    {/*  />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            {/* Project Body */}
                            <div className="card-body">
                                {/*<div className="items">*/}
                                {/*  /!* Single Item *!/*/}
                                {/*  <div className="single-item">*/}
                                {/*    <span>Total raise</span>*/}
                                {/*    <span> {initData.raise}</span>*/}
                                {/*  </div>*/}
                                {/*  /!* Single Item *!/*/}
                                {/*  <div className="single-item">*/}
                                {/*    <span>Valu</span>*/}
                                {/*    <span> {initData.val}</span>*/}
                                {/*  </div>*/}
                                {/*  /!* Single Item *!/*/}
                                {/*</div>*/}
                                <div className="item-progress">
                                    <div className="progress mt-4 mt-md-5">
                                        <div
                                            className="progress-bar text-center"
                                            role="progressbar"
                                            style={{width: `${(totalSupply / maxSupply) * 100}%`}}
                                            aria-valuenow={(totalSupply / maxSupply) * 100}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            {Math.round((totalSupply / maxSupply) * 100)}%
                                        </div>
                                    </div>
                                    <div className="progress-sale d-flex justify-content-between mt-3">
                                        <span>{maxSupply} SUPPLY</span>
                                        <span>{currentPrice} CRO <CroToUsdConverter cro={currentPrice} currencyCode={selectedCurrency}/></span>
                                    </div>
                                </div>
                            </div>
                            {/* Project Footer */}
                            <div className="project-footer d-flex align-items-center mt-4 mt-md-5">
                                <select
                                    onChange={(event) => changeMintCount(event.target.value)}
                                    value={selectedCount}
                                    className="form-control"
                                    style={{width: '25%', marginRight: '10px'}}
                                >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                </select>
                                <button
                                    type="button"
                                    className="btn btn-bordered-white btn-smaller"
                                    onClick={() => mintNFT()}
                                    disabled={disabled}
                                >
                                    {initData.actionBtn}
                                </button>
                                {initData.staking && (
                                    <button
                                        type="button"
                                        className="btn btn-bordered-white btn-smaller"
                                        onClick={() => stakeNFT(initData)}
                                        disabled={disabled}
                                    >
                                        Stake
                                    </button>
                                )}
                                {isAdmin && (
                                    <div className="dropdown">
                                        <button className="btn btn-bordered-white btn-smaller dropdown-toggle" type="button" id="adminDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Old man buttons
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="adminDropdown">
                                            <button
                                                type="button"
                                                className="dropdown-item"
                                                onClick={() => freeMintNFT()}
                                                disabled={disabled}
                                            >
                                                {initData.actionBtnFMint}
                                            </button>
                                            <button
                                                type="button"
                                                className="dropdown-item"
                                                onClick={() => pauseMints()}
                                                disabled={disabled}
                                            >
                                                {initData.actionBtnPauseMints}
                                            </button>
                                            <button
                                                type="button"
                                                className="dropdown-item"
                                                onClick={() => withdrawFunds()}
                                                disabled={disabled}
                                            >
                                                {initData.actionBtnWithdraw}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {/* Social Share */}
                                <div className="social-share ml-auto">
                                    <ul className="d-flex list-unstyled">
                                        {socialData.map((item, idx) => {
                                            return (
                                                <li key={`sd_${idx}`}>
                                                    <a href={item.link}>
                                                        <i className={item.icon}/>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {/* Blockchain Icon */}
                            {/*<div className="blockchain-icon">*/}
                            {/*    <img src={initData.blockchain} alt="img"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 items mt-5 mt-lg-0">
                        <div className="card project-card single-item-content no-hover item ml-lg-4">
                            <h3 className="m-0">{summaryData.title}</h3>
                            <br/> {/* Add a line break */}
                            <p>{summaryData.content_1}</p>
                        </div>
                        <div className="card project-card single-item-content no-hover item p-0 ml-lg-4">
                        <div className="image-over">
                                <img className="card-img-top" src={initData.video_img} alt="img"/>
                            </div>
                            <div className="card-caption col-12 p-0">
                                <div className="card-body p-0">
                                    <div className="play-btn gallery display-yes">
                                        <a href={initData.video_link}>
                                            <i className={initData.video_icon}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="card project-card single-item-content no-hover item ml-lg-4">*/}
                        {/*  <h3 className="m-0">{overviewData.title}</h3>*/}
                        {/*  <p className="mb-0">{overviewData.content}</p>*/}
                        {/*  <h4>{overviewData.list_heading}</h4>*/}
                        {/*  <ul>*/}
                        {/*    {overviewList.map((item, idx) => {*/}
                        {/*      return <li key={`odl_${idx}`}>{item.content}</li>;*/}
                        {/*    })}*/}
                        {/*  </ul>*/}
                        {/*</div>*/}
                        {/*<div className="card project-card single-item-content no-hover item ml-lg-4">*/}
                        {/*  <h3 className="m-0">{tokenmetricsData.title}</h3>*/}
                        {/*  <p>{tokenmetricsData.content}</p>*/}
                        {/*  /!* Token Content *!/*/}
                        {/*  <div className="table-responsive">*/}
                        {/*    <table className="table token-content table-borderless table-sm">*/}
                        {/*      <tbody>*/}
                        {/*      {tokenList.map((item, idx) => {*/}
                        {/*        return (*/}
                        {/*            <tr key={`tdl_${idx}`}>*/}
                        {/*              <td>{item.title}</td>*/}
                        {/*              <td>{item.content}</td>*/}
                        {/*            </tr>*/}
                        {/*        );*/}
                        {/*      })}*/}
                        {/*      </tbody>*/}
                        {/*    </table>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {/*<div className="card project-card single-item-content no-hover item ml-lg-4">*/}
                        {/*  <h3 className="m-0">{roadmapData.title}</h3>*/}
                        {/*  <p>{roadmapData.content_1}</p>*/}
                        {/*  <p>{roadmapData.content_2}</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectSingle;
