import React, {useEffect, useState} from "react";
import {useEthers, ChainId} from "@usedapp/core";

import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {networkHashMap} from "./components/common/constants";
import {useConnectWallet, useSetChain} from "@web3-onboard/react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import ModalMenu from "./components/Modal/ModalMenu";
import { Helmet } from "react-helmet";
// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";
// import {
//   GetProvider,
//   ConnectMetaMaskWallet,
//   ConnectDefiWallet,
//   ConnectToWalletConnect,
//   disconnectWallet,
// } from "./components/Service/WalletService";
import RandomBackground from "./components/common/randomBackground";
import ReactGA from 'react-ga';
import {useLocation} from "react-router";

// Track page views
const TrackPage = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return null;
};

function App() {
    // Initialize Google Analytics
    ReactGA.initialize('G-EJWKDCJWFT');
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet();
    const [{connectedChain, chains}] = useSetChain();
    const {activate, chainId} = useEthers();

    // Get the current pathname
    // const currentPath = window.location.pathname;
    //
    // // Get the metadata for the current page
    // const currentPageMetadata = metadata[currentPath] || metadata["default"];

    useEffect(() => {
        const checkWalletConnectSession = async () => {
            if (window.sessionStorage.walletconnect) {
                console.log('WalletConnect session detected');
                try {
                    await connect({
                        autoSelect: { label: "walletConnect", disableModals: true },
                    });

                    const rpcUrls = {
                        '0x19': process.env.REACT_APP_CRONOS_RPC,
                        '0x152': process.env.REACT_APP_CRONOS_TESTNET_RPC,
                        '0x89': 'https://polygon-mainnet.infura.io/v3/82c058d14e874c9eb52bd3a2caad71ce' // Ensure this URL is correct for Polygon
                    };

                    const rpcUrl = rpcUrls[chainId];


                    const provider = new WalletConnectProvider({
                        qrcode: true,
                        bridge: "https://bridge.walletconnect.org",
                        rpc: { [chainId]: rpcUrl },
                        chainId: parseInt(connectedChain?.id, 16),
                    });

                    await provider.enable();
                    await activate(provider);

                    // Check if wallet is connected before proceeding
                    if (wallet && wallet.accounts && wallet.accounts.length > 0) {
                        console.log('Wallet connected:', wallet);
                    } else {
                        console.error('Wallet not connected properly');
                    }
                } catch (error) {
                    console.error('Error connecting WalletConnect session:', error);
                }
            }
        };

        checkWalletConnectSession();
    }, [wallet, connectedChain]);

    useEffect(() => {
        if (wallet && wallet.accounts && wallet.accounts.length > 0) {
            console.log('Wallet is connected, address:', wallet.accounts[0].address);
            // Perform your Discord callback or other actions here
        } else {
            console.log('Wallet not connected or no account available');
        }
    }, [wallet]);


    const appStyle = {
        backgroundImage: `url(${RandomBackground()})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed", // added this line
        // Add any additional styles here
    };

    return (
        <div style={appStyle}>
            <TrackPage/>
            <ToastContainer/>
            <MyRouts/>
            <ModalMenu/>
        </div>
    );
}

export default App;
