import React, {Component} from 'react';
import {ClipLoader} from 'react-spinners';

const data = [
    {
        id: '1',
        img: '/img/scrappies.png',
        title: 'Scrap Monsters',
        mecha: '3000 SUPPLY',
        busd: '25 CRO',
        action: '/scrappies',
        link: 'scrappies',
        external_link: '/scrappies',
        social: [
            {
                id: '1',
                link: 'https://twitter.com/scrapmonsternft',
                icon: 'fab fa-twitter'
            },
            {
                id: '2',
                link: 'https://discord.gg/KDRYAG37U5',
                icon: 'fab fa-discord'
            }
        ]
    },
    {
        id: '2',
        img: '/img/donkey.gif',
        title: 'Degen Donkeys V2',
        mecha: '50 SUPPLY',
        busd: '350 Cro',
        action: '/degen-donkeys',
        link: '/degen-donkeys',
        external_link: '/degen-donkeys',
        social: [
            {
                id: '1',
                link: 'https://twitter.com/Donkey__Finance',
                icon: 'fab fa-twitter'
            },
            {
                id: '2',
                link: 'https://discord.gg/KDRYAG37U5',
                icon: 'fab fa-discord'
            }
        ]
    }
];

class ProjectOne extends Component {
    render() {
        return (
            <section id="explore" className="project-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="project-slides">
                                <div className="swiper-container slider-mid items">
                                    <div className="swiper-wrapper">
                                        {data.map((item, idx) => {
                                            return (
                                                <div key={`pd_${idx}`} className="swiper-slide item">
                                                    <div
                                                        className="card project-card"
                                                        style={{minHeight: '420px'}}
                                                    >
                                                        <div className="media">
                                                            <a href={item.external_link}>
                                                                <img
                                                                    className="card-img-top avatar-max-lg"
                                                                    src={item.img}
                                                                    alt="img"
                                                                />
                                                            </a>
                                                            <div className="media-body ml-4">
                                                                <a href={item.external_link}>
                                                                    <h4 className="m-0">{item.title}</h4>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="items">
                                                                <div
                                                                    className="progress-sale d-flex justify-content-between mt-3">
                                                                    <span>{item.mecha}</span>
                                                                    <span>{item.busd}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="project-footer d-flex align-items-center mt-4 mt-md-5">
                                                            <a
                                                                className="btn btn-bordered-white btn-smaller"
                                                                href={item.external_link}
                                                            >
                                                                Mint
                                                            </a>
                                                            <div className="social-share ml-auto">
                                                                <ul className="d-flex list-unstyled">
                                                                    {item.social.map((socialItem, socialIdx) => (
                                                                        <li key={`sd_${socialIdx}`}>
                                                                            <a href={socialItem.link}>
                                                                                <i className={socialItem.icon}/>
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {/*<div className="blockchain-icon">*/}
                                                        {/*    <img src={item.blockchain} alt="img"/>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="swiper-pagination"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ProjectOne;
