import React, {useEffect, useState} from 'react';
import {useContractFunction} from '@usedapp/core';
import {Contract, utils} from 'ethers';

import {ChainId, useEthers} from '@usedapp/core';
import {toast} from 'react-toastify';
import CroToUsdConverter from '../../utilities/priceConverter'

import {
    useGetMaxSupply,
    useGetTotalSupply,
    useGetCurrentPrice, useCheckActiveSale
} from '../../utilities/Web3/contract_scrappie';
import {stakeNFT} from "../common/stakingRedirect"


// import CONTRCT_ABI from '../../abi/LimitedERC721A.json';
import CONTRCT_ABI from '../../abi/scrappies.json';
import {useConnectWallet, useSetChain} from "@web3-onboard/react";

const contract_0x = "0xBf0Ca142dE65250c38B9C682c5Dd51d56E18dF6A"

const ContractInterface = new utils.Interface(CONTRCT_ABI);
const RBContract = new Contract(contract_0x, ContractInterface);


const initData = {
    img: '/img/scrappies.png',
    blockchain: '',
    title: 'Scrap Monsters',
    reg_date: 'ee',
    raise: '222.5k',
    val: '$13350',
    allocation: '$0',
    progress: '0%',
    mecha: '3000  SUPPLY',
    busd: '25 CRO',
    actionBtn: 'Mint NFT',
    actionBtnFMint: 'reserve (ADMIN)',
    video_img: '/img/scrappies.png',
    video_icon: 'fa-solid fa-play',
    video_link: 'https://youtu.be/ki9TdF60FQY',
    project_admin: '0x6802e6E9147803607D213eB6B5A99639D792D9Ae',
    actionBtnPauseMints: 'Pause mints (ADMIN)',
    actionBtnWithdraw: 'Withdraw funds (ADMIN)',
    staking: true,
    stakeLink: "https://stake.timmy.finance/0x58fFbD0C8B04C523EC12003D01cb1e067471375e"
};

const summaryData = {
    title: 'Project Summary',
    content_1:
        'Introducing Scrap Monsters, a revolutionary NFT collection merging the digital world with real-world sustainability efforts. Embark on a journey through a whimsical universe populated by 3000 unique Scrap Monsters, each brimming with personality and charm.',
    content_2:
        'Inspired by the ethos of recycling and environmental stewardship, Scrap Monsters are more than just digital collectibles—they\'re a testament to our commitment to making a positive impact on the world around us. Forged from the remnants of discarded cans, bottles, and scrap metals, each monster is a testament to the beauty that can arise from the discarded and forgotten.',
    content_3:
        'As a Scrap Monsters collector, you\'re not just acquiring NFTs; you\'re joining a community dedicated to sustainability and innovation. Participate in our real-world recycling initiatives, where we venture out to collect cans, bottles, and scrap metals for recycling. Your contributions directly fuel our staking pool, ensuring the sustainability and growth of the Scrap Monsters ecosystem.',
    content_4:
        'But the journey doesn\'t end there. Engage with fellow collectors, collaborate on community projects, and unlock special rewards as you help us build a greener, more sustainable future. Whether you\'re a seasoned NFT enthusiast or a newcomer to the space, there\'s a place for you in the Scrap Monsters community.',
    content_5:
        'Join us in reshaping the narrative around NFTs and sustainability. Embrace the magic of Scrap Monsters and become a part of something truly extraordinary.'
};

const socialData = [
    {
        id: '1',
        link: 'https://twitter.com/scrapmonsternft',
        icon: 'fab fa-twitter'
    },
    {
        id: '2',
        link: 'https://discord.gg/KDRYAG37U5',
        icon: 'fab fa-discord'
    }
];


const ProjectSingle = () => {
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet();
    const [isAdmin, setIsAdmin] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [selectedCount, setSelectedCount] = useState(1);
    const {activateBrowserWallet, account} = useEthers();
    const [{ connectedChain, chains, settingChain }, setChain] = useSetChain();
    const {currentPrice} = useGetCurrentPrice();
    const {maxSupply} = useGetMaxSupply();
    const {totalSupply} = useGetTotalSupply();
    const {activeSale} = useCheckActiveSale();
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [correctChain, setCorrectChain] = useState(false);
    // const feeData = await provider.getFeeData();

    // transaction instance
    const {state, send} = useContractFunction(RBContract, 'mint', {
        transactionName: 'Mint'
    });

    const {state: stateFreemint, send: sendFreeMint} = useContractFunction(RBContract, 'freemint', {
        transactionName: 'freemint'
    });

    const {state: statePauseMints, send: sendPauseMints} = useContractFunction(RBContract, 'pauseMints', {
        transactionName: 'pause mints'
    });

    const {state: stateWithdraw, send: sendWithdraw} = useContractFunction(RBContract, 'withdrawfunds', {
        transactionName: 'withdrawfunds'
    });

    const desiredChainId = ChainId.Cronos;
    // Force user to change chain to a specific chain ID (e.g., ChainId.Cronos)
    useEffect( async () => {
        if (connectedChain?.id !== "0x19") {
            await setChain({chainId: desiredChainId});
            setDisabled(true)
        } else {
            setCorrectChain(true)
        }
    }, [connectedChain, setChain, ChainId, desiredChainId])


    useEffect(() => {
        const address = wallet?.accounts[0]?.address; // Add null checks for safety
        const checkWalletStatus = async () => {
            if (address) {
                // Set admin status
                setIsAdmin(initData.project_admin.toLowerCase().includes(address.toLowerCase()));
            } else {
                setIsAdmin(false);
            }

        };
        checkWalletStatus();
    }, [wallet]); // Add wallet as a dependency

    useEffect(() => {
        if (stateWithdraw.status === 'PendingSignature') {
            toast.warning('Withdraw in progress... Please wait.', {autoClose: 6000});
        }
        if (stateWithdraw.status === 'Success') {
            setDisabled(false);
            setSelectedCount(1);
            toast.success('Congratulations! Withdraw Successful.', {autoClose: 6000});
        }
        if (stateWithdraw.status === 'Exception') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(stateFreemint.errorMessage, {autoClose: 6000});
        }
        if (stateWithdraw.status === 'Fail') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(stateFreemint.errorMessage, {autoClose: 6000});
        }

        delete stateWithdraw['status'];
    }, [stateWithdraw]);


    useEffect(() => {
        if (statePauseMints.status === 'PendingSignature') {
            if (activeSale) {
                toast.warning('Mint pausing in progress... Please wait.', {autoClose: 6000});
            } else {
                toast.warning('Mint unpausing in progress... Please wait.', {autoClose: 6000});
            }
        }
        if (statePauseMints.status === 'Success') {
            setDisabled(false);
            setSelectedCount(1);
            toast.success('Successfully changed minting status!', {autoClose: 6000});
        }
        if (statePauseMints.status === 'Exception') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(statePauseMints.errorMessage, {autoClose: 6000});
        }
        if (statePauseMints.status === 'Fail') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(statePauseMints.errorMessage, {autoClose: 6000});
        }

        delete statePauseMints['status'];
    }, [statePauseMints]);

    useEffect(() => {
        if (stateFreemint.status === 'PendingSignature') {
            toast.warning(' Free Minting in progress... Please wait.', {autoClose: 6000});
        }
        if (stateFreemint.status === 'Success') {
            setDisabled(false);
            setSelectedCount(1);
            toast.success('Congratulations! Free Mint Successful.', {autoClose: 6000});
        }
        if (stateFreemint.status === 'Exception') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(stateFreemint.errorMessage, {autoClose: 6000});
        }
        if (stateFreemint.status === 'Fail') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(stateFreemint.errorMessage, {autoClose: 6000});
        }

        delete stateFreemint['status'];
    }, [stateFreemint]);

    useEffect(() => {
        if (state.status === 'PendingSignature') {
            toast.warning('Minting in progress... Please wait.', {autoClose: 6000});
        }
        if (state.status === 'Success') {
            setDisabled(false);
            setSelectedCount(1);
            toast.success('Congratulations! Mint Successful.', {autoClose: 6000});
        }
        if (state.status === 'Exception') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(state.errorMessage, {autoClose: 6000});
        }
        if (state.status === 'Fail') {
            setDisabled(false);
            setSelectedCount(1);
            toast.error(state.errorMessage, {autoClose: 6000});
        }

        delete state['status'];
    }, [state]);

    useEffect(() => {
        const selectedCurrencyStorage = localStorage.getItem('selectedCurrency')
        if (selectedCurrencyStorage) {
            setSelectedCurrency(selectedCurrencyStorage);
        }
    }, []);

    const changeMintCount = (count) => {
        if (correctChain) {
            setSelectedCount(count);
        }
    };

    async function pauseMints() {
        if (!account) {
            activateBrowserWallet();
            return;
        }
        if (!isAdmin) return;
        try {
            if (correctChain) {
                if (activeSale) {
                    sendPauseMints(false)
                } else {
                    sendPauseMints(true)
                }
            }
        } catch (error) {
            toast.error(error?.message);
            setDisabled(false);
            setSelectedCount(1);
        }
    }

    async function mintNFT() {
        if (disabled) return;
        if (!activeSale) {
            toast.error('Mints are currently paused.');
            return;
        }
        if (!account) {
            activateBrowserWallet();
            return;
        }
        try {
            if (correctChain) {
                const amount = parseInt(selectedCount); // 1 NFT per transaction can user mint

                const totalCost = (currentPrice * selectedCount).toString(); // Convert to string

                send(amount, {
                    value: utils.parseEther(totalCost),
                    gasLimit: utils.parseUnits('190000', 'wei'),
                });
            }
        } catch (error) {
            toast.error(error?.message);
            setDisabled(false);
            setSelectedCount(1);
        }
    }

    async function freeMintNFT() {
        if (!account) {
            activateBrowserWallet();
            return;
        }
        if (!isAdmin) return;
        try {
            if (correctChain) {
                const address = wallet?.accounts[0].address
                const amount = parseInt(selectedCount); // 1 NFT per transaction can user mint

                sendFreeMint(address, amount, {
                    gasLimit: utils.parseUnits('900000', 'wei'),
                });
            }

        } catch (error) {
            toast.error(error?.message);
            setDisabled(false);
            setSelectedCount(1);
        }
    }

    async function withdrawFunds() {
        if (!account) {
            activateBrowserWallet();
            return;
        }
        if (!isAdmin) return;
        try {
            if (correctChain) {
                await sendWithdraw({
                    gasLimit: utils.parseUnits('96000', 'wei'),
                });
            }
        } catch (error) {
            toast.error(error?.message);
            setDisabled(false);
            setSelectedCount(1);
        }
    }


    // function floatToStr(num) {
    //   return num.toString().indexOf('.') === -1 ? num.toFixed(1) : num.toString();
    // }

    return (
        <section className="item-details-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-5">
                        {/* Project Card */}
                        <div className="card project-card no-hover">
                            <div className="media">
                                <img
                                    className="card-img-top avatar-max-lg"
                                    src={initData.img}
                                    alt="img"
                                />
                                <div className="media-body ml-4">
                                    <h4 className="m-0">{initData.title}</h4>
                                    {/*<div className="countdown-times">*/}
                                    {/*  <h6 className="my-2">Launched:</h6>*/}
                                    {/*  <div*/}
                                    {/*    className="countdown d-flex"*/}
                                    {/*    data-date={initData.reg_date}*/}
                                    {/*  />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            {/* Project Body */}
                            <div className="card-body">
                                {/*<div className="items">*/}
                                {/*  /!* Single Item *!/*/}
                                {/*  <div className="single-item">*/}
                                {/*    <span>Total raise</span>*/}
                                {/*    <span> {initData.raise}</span>*/}
                                {/*  </div>*/}
                                {/*  /!* Single Item *!/*/}
                                {/*  <div className="single-item">*/}
                                {/*    <span>Valu</span>*/}
                                {/*    <span> {initData.val}</span>*/}
                                {/*  </div>*/}
                                {/*  /!* Single Item *!/*/}
                                {/*</div>*/}
                                <div className="item-progress">
                                    <div className="progress mt-4 mt-md-5">
                                        <div
                                            className="progress-bar text-center"
                                            role="progressbar"
                                            style={{width: `${(totalSupply / maxSupply) * 100}%`}}
                                            aria-valuenow={(totalSupply / maxSupply) * 100}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            {Math.round((totalSupply / maxSupply) * 100)}%
                                        </div>
                                    </div>
                                    <div className="progress-sale d-flex justify-content-between mt-3">
                                        <span>{maxSupply} SUPPLY</span>
                                        <span>{currentPrice} CRO <CroToUsdConverter cro={currentPrice} currencyCode={selectedCurrency}/></span>
                                    </div>
                                </div>
                            </div>
                            {/* Project Footer */}
                            <div className="project-footer d-flex align-items-center mt-4 mt-md-5">
                                <select
                                    onChange={(event) => changeMintCount(event.target.value)}
                                    value={selectedCount}
                                    className="form-control"
                                    style={{width: '25%', marginRight: '10px'}}
                                >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                </select>
                                <button
                                    type="button"
                                    className="btn btn-bordered-white btn-smaller"
                                    onClick={() => mintNFT()}
                                    disabled={disabled}
                                >
                                    {initData.actionBtn}
                                </button>
                                {initData.staking && (
                                    <button
                                        type="button"
                                        className="btn btn-bordered-white btn-smaller"
                                        onClick={() => stakeNFT(initData)}
                                        disabled={disabled}
                                    >
                                        Stake
                                    </button>
                                )}
                                {isAdmin && (
                                    <div className="dropdown">
                                        <button className="btn btn-bordered-white btn-smaller dropdown-toggle"
                                                type="button" id="adminDropdown" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            Old man buttons
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="adminDropdown">
                                            <button
                                                type="button"
                                                className="dropdown-item"
                                                onClick={() => freeMintNFT()}
                                                disabled={disabled}
                                            >
                                                {initData.actionBtnFMint}
                                            </button>
                                            <button
                                                type="button"
                                                className="dropdown-item"
                                                onClick={() => pauseMints()}
                                                disabled={disabled}
                                            >
                                                {initData.actionBtnPauseMints}
                                            </button>
                                            <button
                                                type="button"
                                                className="dropdown-item"
                                                onClick={() => withdrawFunds()}
                                                disabled={disabled}
                                            >
                                                {initData.actionBtnWithdraw}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {/* Social Share */}
                                <div className="social-share ml-auto">
                                    <ul className="d-flex list-unstyled">
                                        {socialData.map((item, idx) => {
                                            return (
                                                <li key={`sd_${idx}`}>
                                                    <a href={item.link}>
                                                        <i className={item.icon}/>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {/* Blockchain Icon */}
                            {/*<div className="blockchain-icon">*/}
                            {/*    <img src={initData.blockchain} alt="img"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 items mt-5 mt-lg-0">
                        <div className="card project-card single-item-content no-hover item ml-lg-4">
                            <h3 className="m-0">{summaryData.title}</h3>
                            <br/> {/* Add a line break */}
                            <p>{summaryData.content_1}</p>
                            <p>{summaryData.content_2}</p>
                            <p>{summaryData.content_3}</p>
                            <p>{summaryData.content_4}</p>
                            <p>{summaryData.content_5}</p>
                        </div>
                        <div className="card project-card single-item-content no-hover item p-0 ml-lg-4">
                            <div className="image-over">
                                <img className="card-img-top" src={initData.video_img} alt="img"/>
                            </div>
                            <div className="card-caption col-12 p-0">
                                <div className="card-body p-0">
                                    <div className="play-btn gallery display-yes">
                                        <a href={initData.video_link}>
                                            <i className={initData.video_icon}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="card project-card single-item-content no-hover item ml-lg-4">*/}
                        {/*  <h3 className="m-0">{overviewData.title}</h3>*/}
                        {/*  <p className="mb-0">{overviewData.content}</p>*/}
                        {/*  <h4>{overviewData.list_heading}</h4>*/}
                        {/*  <ul>*/}
                        {/*    {overviewList.map((item, idx) => {*/}
                        {/*      return <li key={`odl_${idx}`}>{item.content}</li>;*/}
                        {/*    })}*/}
                        {/*  </ul>*/}
                        {/*</div>*/}
                        {/*<div className="card project-card single-item-content no-hover item ml-lg-4">*/}
                        {/*  <h3 className="m-0">{tokenmetricsData.title}</h3>*/}
                        {/*  <p>{tokenmetricsData.content}</p>*/}
                        {/*  /!* Token Content *!/*/}
                        {/*  <div className="table-responsive">*/}
                        {/*    <table className="table token-content table-borderless table-sm">*/}
                        {/*      <tbody>*/}
                        {/*      {tokenList.map((item, idx) => {*/}
                        {/*        return (*/}
                        {/*            <tr key={`tdl_${idx}`}>*/}
                        {/*              <td>{item.title}</td>*/}
                        {/*              <td>{item.content}</td>*/}
                        {/*            </tr>*/}
                        {/*        );*/}
                        {/*      })}*/}
                        {/*      </tbody>*/}
                        {/*    </table>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {/*<div className="card project-card single-item-content no-hover item ml-lg-4">*/}
                        {/*  <h3 className="m-0">{roadmapData.title}</h3>*/}
                        {/*  <p>{roadmapData.content_1}</p>*/}
                        {/*  <p>{roadmapData.content_2}</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectSingle;
