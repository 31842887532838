import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {Wallet} from "../onboard/onboard";

const ModalMenu = () => {


    return (
        <>
            <style>
                {`
          .shiny-box, .coming-soon {
            display: inline-block;
            padding: 2px 8px;
            background: linear-gradient(45deg, #f2c94c, #f39c12, #e67e22, #d35400);
            border-radius: 12px;
            color: white;
            font-weight: bold;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            margin-left: 5px;
            animation: shine 1.5s infinite alternate;
          }

          .new-symbol {
            display: inline-block;
            background-color: #2ecc71;
            color: white;
            padding: 2px 8px;
            border-radius: 12px;
            font-weight: bold;
            margin-left: 5px;
          }

          @keyframes shine {
            from {
              background-position: -200% center;
            }
            to {
              background-position: 200% center;
            }
          }
        `}
            </style>
            <div id="menu" className="modal fade p-0">
                <div className="modal-dialog dialog-animated">
                    <div className="modal-content h-100">
                        <div className="modal-header" data-dismiss="modal">
                            Menu <i className="far fa-times-circle icon-close"/>
                        </div>
                        <div className="menu modal-body">
                            <div className="row w-100">
                                <ul className="navbar-nav items mx-auto">
                                    <li className="nav-item">
                                        <a href="/" className="nav-link">
                                            Home
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a href="#" className="nav-link">
                                            Projects <i className="icon-arrow-down"></i>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <a href="/degen-donkeys" className="nav-link">
                                                    Degen Donkeys V2 <span className="new-symbol">New</span>
                                                </a>
                                                <a href="/scrappies" className="nav-link">
                                                    Scrap Monsters
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="https://stake.timmy.finance" className="nav-link" target="_blank"
                                           rel="noopener noreferrer">
                                            Timmy Finance Staking
                                        </a>
                                        <a href="https://staking.timmy.finance" className="nav-link" target="_blank"
                                           rel="noopener noreferrer">
                                            V2 Staking <span className="new-symbol">New</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalMenu;


