import React from 'react';
import { useSetChain } from '@web3-onboard/react';
import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ModalMenu from '../components/Modal/ModalMenu';
import Footer from '../components/Footer/Footer';
import CoinflipGameCronos from '../components/Project/coinflip/Cronos';
import CoinflipGamePolygon from '../components/Project/coinflip/Polygon';
import { Helmet } from "react-helmet";

const CoinFlip = () => {
    const [{ connectedChain }] = useSetChain();

    // Define the appropriate component based on the connected chain
    let CoinflipGameComponent;
    if (connectedChain?.id === '0x19') { // Cronos
        CoinflipGameComponent = CoinflipGameCronos;
    } else if (connectedChain?.id === '0x89') { // Polygon
        CoinflipGameComponent = CoinflipGamePolygon;
    } else {
        CoinflipGameComponent = () => <div>Please connect to either Cronos or Polygon.</div>;
    }

    return (
        <div className="main">
            <Helmet>
                <meta name="theme-color" content="#ff6600" />
                <title>Timmy Finance - Scrap Monsters</title>
                <meta name="description" content="Timmy Finance - Timmy Flip" />
                <meta property="og:title" content="Timmy Finance - Timmy Flip" />
                <meta property="og:description" content="Timmy Finance - Timmy Flip" />
                <meta property="og:image" content="https://timmy.finance/img/projects/coin.png" />
                <meta property="og:url" content="https://timmy.finance" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://timmy.finance/coinflip" />
            </Helmet>
            <Header />
            <Breadcrumb title="Coin Flip" subpage="Arcade" page="Coin Flip" />
            <CoinflipGameComponent />
            <Footer />
            <ModalMenu />
        </div>
    );
};

export default CoinFlip;
