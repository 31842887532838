import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const CroToUsdConverter = ({ cro = 0, currencyCode }) => {
    const [croPrice, setCroPrice] = useState(null);
    const [convertedAmount, setConvertedAmount] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    currencyCode = "USD"

    useEffect(() => {
        const fetchCroPrice = async () => {
            try {
                const response = await axios.get(
                    'https://stakingapi.timmy.finance/utils/croPrice'
                );
                console.log(response.data);
                const croPriceInUsd = response.data['croPrice'];
                setCroPrice(croPriceInUsd);
            } catch (error) {
                console.error('Error fetching CRO price:', error);
                setCroPrice(null); // Reset price if fetch fails
            }
        };
        fetchCroPrice();
    }, []);

    useEffect(() => {
        if (currencyCode) {
            const currencyNames = {
                us: 'USD',
                au: 'AUD',
                nz: 'NZD',
                gb: 'GBP',
                ca: 'CAD'
                // Add more mappings as needed
            };

            setSelectedCurrency(currencyNames[currencyCode] || 'USD'); // Default to USD if not found
        }
    }, [currencyCode]);

    useEffect(() => {
        console.log('Received cro prop:', cro);
        if (cro && croPrice && selectedCurrency) {
            const exchangeRates = {
                USD: 1,
                AUD: 1.5125,
                NZD: 1.660995,
                GBP: 0.79633304,
                CAD: 1.366612
            };

            const amountInUsd = cro * croPrice;
            const exchangeRate = exchangeRates[selectedCurrency] || 1;
            const amountInSelectedCurrency = amountInUsd * exchangeRate;
            const roundedAmount = Number(amountInSelectedCurrency.toFixed(2));

            setConvertedAmount(roundedAmount);
        } else {
            console.log('Conversion not possible, check props.');
            setConvertedAmount(null);
        }
    }, [cro, croPrice, selectedCurrency]);


    return (
        <span style={{ color: '#4c4b4b' }}>
            {convertedAmount !== null ? `${convertedAmount} ${selectedCurrency}` : 'Loading...'}
        </span>
    );
};

CroToUsdConverter.propTypes = {
    cro: PropTypes.number,
    currencyCode: PropTypes.string.isRequired
};

export default CroToUsdConverter;
