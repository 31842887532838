// DiscordConnect.js
import React, { useEffect } from 'react';

const ConnectDiscord = () => { // Rename to start with an uppercase letter

    useEffect(() => {
        const discordAuthUrl = `https://discord.com/oauth2/authorize?client_id=1236968683419598849&response_type=code&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_DISCORD_CALLBACK}`)}&scope=identify`;
        window.location.href = discordAuthUrl
    }, []);

    return null; // or a loading spinner or message
};

export default ConnectDiscord;
