import React from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
// import Project from '../components/Project/ProjectOne';
import ProjectTwo from '../components/Project/ProjectTwo';
// import Content from '../components/Content/Content';
import Team from '../components/Team/Team';
// import Work from '../components/Work/Work';
// import Cta from '../components/Cta/Cta';
import Footer from '../components/Footer/Footer';
import {Helmet} from "react-helmet";
// import ModalSearch from '../components/Modal/ModalSearch';
// import ModalMenu from '../components/Modal/ModalMenu';
// import NftRaffle from '../components/Project/Raffle';
// import LilWhale from '../components/Project/LilWhale';


const ThemeOne = () => {
  return (
    <div className="main">
        <Helmet>
            <meta name="theme-color" content="#ff6600"/>

            <title>Timmy Finance - Home</title>
            <meta name="description" content="Timmy Finance - Home"/>

            <meta property="og:title" content="Timmy Finance - Home"/>
            <meta property="og:description" content="Timmy Finance - Home"/>
            <meta property="og:image" content="https://timmy.finance/img/timmothy.png"/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://timmy.finance/"/>
        </Helmet>
        <Header/>
        <Hero/>
        {/*<Project />*/}
        {/*<LilWhale />*/}
        <ProjectTwo/>
        {/*<NftRaffle />*/}
      {/*<Content />*/}
      <Team />
      {/*<Cta />*/}
      <Footer />
      {/*<ModalSearch />*/}
      {/*<ModalMenu />*/}
    </div>
  );
};

export default ThemeOne;
