// Unlink.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useConnectWallet } from '@web3-onboard/react';
import { useNavigate } from 'react-router-dom';  // Updated import

const Unlink = () => {
    const [{ wallet, connecting }] = useConnectWallet();
    const [status, setStatus] = useState('');
    const navigate = useNavigate();  // Initialize useNavigate

    useEffect(() => {
        const unlinkAccount = async () => {
            if (wallet && wallet.accounts && wallet.accounts.length > 0) {
                const walletAddress = wallet.accounts[0].address;
                try {
                    const response = await axios.delete(`${process.env.REACT_APP_DISCORD_URI}/link/remove`, {
                        data: { walletAddress }
                    });
                    if (response.data.success) {
                        setStatus('Unlink Successful');
                        setTimeout(() => {
                            navigate('/');  // Navigate to home page after unlinking
                        }, 3000);
                    } else {
                        setStatus('Unlink Failed');
                    }
                } catch (error) {
                    console.error('Error unlinking account:', error);
                    setStatus('Error Occurred');
                }
            }
        };
        unlinkAccount();
    }, [wallet, navigate]);  // Include navigate in the dependency array

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>{status || 'Unlinking your Discord account...'}</h2>
        </div>
    );
};

export default Unlink;
