import React, { Component } from 'react';
import axios from 'axios';


class Footer extends Component {


  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              {/* Footer Items */}
                <div className="footer-items">
                    {/* Logo */}
                    <a className="navbar-brand" href="/">
                        <img src="https://imgur.com/KzUjtzC.gif" alt="Timmy Finance"  style={{ width: '50px', height: '50px' }}/>
                    </a>
                    {/* Social Icons */}
                    <div className="social-icons d-flex justify-content-center my-4">
                        {/*{this.state.socialData.map((item, idx) => {*/}
                        {/*  return (*/}
                        {/*    <a key={`fsd_${idx}`} className="facebook" href={item.link} target="_blank">*/}
                        {/*      <i className={item.icon} />*/}
                        {/*      <i className={item.icon} />*/}
                        {/*    </a>*/}
                        {/*  );*/}
                        {/*})}*/}
                    </div>
                    <ul className="list-inline">
                        {/*{this.state.widgetData.map((item, idx) => {*/}
                        {/*  return (*/}
                        {/*    <li key={`fwd_${idx}`} className="list-inline-item">*/}
                        {/*      <a href={item.link}>{item.text}</a>*/}
                        {/*    </li>*/}
                        {/*  );*/}
                        {/*})}*/}
                    </ul>
                    {/* Legal Disclaimer */}
                    <p className="legal-disclaimer">

                        All content, including but not limited to logos, designs, artwork, and textual materials,
                        contained within the Timmy Finance ecosystem is protected by copyright law. Timmy Finance
                        reserves all rights to these materials.

                        No part of the Timmy Finance brand, including its associated products, services, and promotional
                        materials, may be reproduced, distributed, or transmitted in any form or by any means,
                        electronic or mechanical, without the prior written permission of Timmy Finance.

                        Any unauthorized use, reproduction, or distribution of Timmy Finance's copyrighted materials is
                        strictly prohibited and may result in legal action.
                    </p>
                    {/* Copyright Area */}
                    <div className="copyright-area py-4">
                        ©2024 Timmy Finance, All Rights Reserved By <a href="https://timmy.finance" target="_blank">Timmy
                        Finance</a>
                    </div>
                </div>
                {/* Scroll To Top */}
                <div id="scroll-to-top" className="scroll-to-top">
                    <a href="#header" className="smooth-anchor">
                  <i className="fa-solid fa-arrow-up" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
