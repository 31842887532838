import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Project from '../components/Project/DegenDonkeys';
import Cta from '../components/Cta/Cta';
import Footer from '../components/Footer/Footer';
import {Helmet} from "react-helmet";


const DegenDonkeys = () => {
        return (
            <div className="main">
                    <Helmet>
                            <meta name="theme-color" content="#ff6600" />

                            <title>Timmy Finance - Degen Donkeys V2</title>
                            <meta name="description" content="Timmy Finance - Degen Donkeys V2" />

                            <meta property="og:title" content="Timmy Finance - Degen Donkeys V2" />
                            <meta property="og:description" content="Timmy Finance - Degen Donkeys V2" />
                            <meta property="og:image" content="https://timmy.finance/img/projects/donkey.png" />
                            <meta property="og:type" content="website" />
                            <meta property="og:url" content="https://timmy.finance/degen-donkeys" />
                    </Helmet>
                <Header />
                <Breadcrumb title="Degen Donkeys V2" subpage="VIP" page="Degen Donkeys V2" />
                <Project />
                {/*<Cta />*/}
                <Footer />
                {/*<ModalSearch />*/}
                {/*<ModalMenu />*/}
            </div>
        );
}

export default DegenDonkeys;