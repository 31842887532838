import React from 'react';
import { useSetChain } from '@web3-onboard/react';
import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ModalMenu from '../components/Modal/ModalMenu';
import Footer from '../components/Footer/Footer';
import Unlink from '../components/Discord/Unlink'


const UnlinkDiscord = () => {

    return (
        <div className="main">
            <Header />
            <Breadcrumb title="Discord Unlink" subpage="Discord" page="Discord Unlink" />
            <Unlink />
            <Footer />
            <ModalMenu />
        </div>
    );
};

export default UnlinkDiscord;
