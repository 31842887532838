import React, { Component } from 'react';

const TeamCard = ({ teamMember }) => (
    <div className="swiper-slide item">
        <div className="card team-card text-center">
            <a className="team-photo d-inline-block" href={teamMember.socialProfile}>
                <img className="mx-auto" src={teamMember.img} alt="img" />
            </a>
            <div className="team-content mt-3">
                <a href={teamMember.socialProfile}>
                    <h4 className="mb-0">{teamMember.name}</h4>
                </a>
                <span className="d-inline-block mt-2 mb-3">{teamMember.designation}</span>
            </div>
        </div>
    </div>
);

class Team extends Component {
    state = {
        initData: {
            sub_heading: "Creative",
            heading: "Our Team",
            btn: "View Team"
        },
        data: [
            {
                id: "1",
                img: "/img/timtim.png",
                name: "Timmy",
                designation: "Founder",
                socialProfile: "https://twitter.com/TimmyNoIceCream"
            },
            {
                id: "2",
                img: "/img/duke.jpg",
                name: "Duke",
                designation: "Co Founder",
                socialProfile: "https://twitter.com/KBurrel10718939"
            },
            {
                id: "3",
                img: "/img/feedle.png",
                name: "Feedle",
                designation: "Admin",
                socialProfile: "https://twitter.com/feedle_"
            },
            {
                id: "4",
                img: "/img/team/amburr.png",
                name: "Amburr",
                designation: "Graphics Department",
                socialProfile: ""
            },
        ],
    };

    render() {
        const { initData, data } = this.state;

        return (
            <section className="team-area p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="intro d-flex justify-content-between align-items-end m-0">
                                <div className="intro-content">
                                    <span className="intro-text">{initData.sub_heading}</span>
                                    <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                </div>
                                {/*<div className="intro-btn">*/}
                                {/*    <a className="btn content-btn" href="https://medium.com/@KangaDegens/team-of-roo-finance-6aab1398712">{initData.btn}</a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="team-slides">
                        <div className="swiper-container slider-min items">
                            <div className="swiper-wrapper">
                                {data.map((teamMember, idx) => (
                                    <TeamCard key={idx} teamMember={teamMember} />
                                ))}
                            </div>
                            <div className="swiper-pagination" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Team;
