import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ConnectDiscord from '../components/Discord/DiscordConnect';
import Footer from '../components/Footer/Footer';


const DiscordConnect = () => {
    return (
        <div className="main">
            <Header />
            <Breadcrumb title="Discord Connect" subpage="Home" page="Discord Connect" />
            <ConnectDiscord />
            <Footer />
        </div>
    );
}

export default DiscordConnect;