import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useConnectWallet } from '@web3-onboard/react';

const Callback = () => {
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [isWalletReady, setIsWalletReady] = useState(false);
    const [linkStatus, setLinkStatus] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (wallet && wallet.accounts && wallet.accounts.length > 0) {
            setIsWalletReady(true);
            console.log('Wallet is ready with address:', wallet.accounts[0].address);
        } else if (!connecting) {
            console.error('Wallet not connected yet or no accounts available');
            setIsWalletReady(false);
        }
    }, [wallet, connecting]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');

                if (code && isWalletReady) {
                    const walletAddress = wallet.accounts[0].address;
                    const userId = localStorage.getItem('discordUserId'); // Assuming you save discordUserId in localStorage after connecting Discord
                    const payload = { code, walletAddress, userId };

                    const checkResponse = await axios.get(`${process.env.REACT_APP_DISCORD_URI}/link/check`, {
                        params: { userId, walletAddress }
                    });

                    if (checkResponse.data.linked) {
                        if (checkResponse.data.error) {
                            setLinkStatus('This wallet or Discord account is already linked to another account. Please unlink first.');
                        } else {
                            setLinkStatus('Already Linked');
                        }
                    } else {
                        const response = await axios.post(`${process.env.REACT_APP_DISCORD_URI}/auth/callback`, payload);
                        setLinkStatus('Link Completed');
                        console.log('Discord response:', response.data);
                    }
                } else {
                    console.error('No code found or wallet not ready');
                    setLinkStatus('Please connect your wallet to proceed.');
                }
            } catch (error) {
                console.error('Error during Discord OAuth callback:', error);
                if (error.response && error.response.status === 409) {
                    setLinkStatus('Conflict: This wallet or Discord account is already linked to another account. Please unlink first.');
                } else {
                    setLinkStatus('Error Occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        if (isWalletReady) {
            fetchData();
        }
    }, [isWalletReady, wallet]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>{loading ? 'Processing Discord OAuth...' : linkStatus}</h2>
            {linkStatus === 'Link Completed' && <p>Thank you! Your Discord account has been successfully linked.</p>}
            {linkStatus === 'Already Linked' && <p>This wallet is already linked to a Discord account.</p>}
            {linkStatus === 'Conflict: This wallet or Discord account is already linked to another account. Please unlink first.' && (
                <p>This wallet or Discord account is already linked to another account. Please unlink first.</p>
            )}
            {linkStatus === 'Error Occurred' && <p>There was an error processing your request. Please try again.</p>}
            {linkStatus === 'Please connect your wallet to proceed.' && (
                <div>
                    <p>Please connect your wallet to proceed with the verification.</p>
                    <button onClick={() => connect()}>Connect Wallet</button>
                </div>
            )}
        </div>
    );
};

export default Callback;
