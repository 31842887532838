import React, { useEffect, useState } from 'react';
import axios from 'axios';

const RandomMessage = () => {
    // State to hold the randomly selected message
    const [randomMessage, setRandomMessage] = useState('');

    // Function to select a random message from the array
    const selectRandomMessage = (messages) => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setRandomMessage(messages[randomIndex]);
    };

    // Fetch data from the API endpoint
    useEffect(() => {
        axios.get('https://stakingapi.timmy.finance/settings/motd') // Assuming your backend endpoint is '/motd'
            .then(response => {
                const data = response.data;
                // Check if data is an array and not empty
                if (Array.isArray(data) && data.length > 0) {
                    console.log('Messages data:', data);
                    selectRandomMessage(data);
                } else {
                    console.error('No valid data returned from API');
                }
            })
            .catch(error => {
                console.error('Error fetching data from API:', error);
            });
    }, []);

    // Return the random message
    return randomMessage;
};

export default RandomMessage;
