import React from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Project from '../components/Project/ScrapMonsters';
// import Cta from '../components/Cta/Cta';
import Footer from '../components/Footer/Footer';
import {Helmet} from "react-helmet";
// import ModalSearch from '../components/Modal/ModalSearch';
// import ModalMenu from '../components/Modal/ModalMenu';

const ScrapMonsters = () => {
        return (
            <div className="main">
                    <Helmet>
                            <meta name="theme-color" content="#ff6600"/>

                            <title>Timmy Finance - Scrap Monsters</title>
                            <meta name="description" content="Timmy Finance - Scrap Monsters"/>

                            <meta property="og:title" content="Timmy Finance - Scrap Monsters"/>
                            <meta property="og:description" content="Timmy Finance - Scrap Monsters"/>
                            <meta property="og:image" content="https://timmy.finance/img/projects/scrappies.png"/>
                            <meta property="og:type" content="website"/>
                            <meta property="og:url" content="https://timmy.finance/scrappies"/>
                    </Helmet>
                    <Header/>
                    <Breadcrumb title="Scrap Monsters" subpage="VIP" page="Scrap Monsters" />
                <Project />
                {/*<Cta />*/}
                <Footer />
                {/*<ModalSearch />*/}
                {/*<ModalMenu />*/}
            </div>
        );
}

export default ScrapMonsters;