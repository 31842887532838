import React from 'react';
import { useSetChain } from '@web3-onboard/react';
import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ModalMenu from '../components/Modal/ModalMenu';
import Footer from '../components/Footer/Footer';
import Callback from '../components/Discord/Callback'


const CoinFlip = () => {

    return (
        <div className="main">
            <Header />
            <Breadcrumb title="Discord Connect" subpage="Home" page="Discord Connect" />
            <Callback />
            <Footer />
            <ModalMenu />
        </div>
    );
};

export default CoinFlip;
