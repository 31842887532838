// MyRouts.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import DegenDonkeys from "../themes/DegenDonkeys";
import ThemeOne from "../themes/theme-one";
import ScrapMonsters from "../themes/ScrapMonsters";
import CoinFlip from "../themes/coinflip";
import Callback from "../themes/DiscordCallback";
import UnlinkDiscord from "../themes/unlinkDiscord";
import DiscordConnect from "../themes/connectDiscord"; // Import the component correctly
import Raffle from "../themes/Raffle45k";

const MyRouts = () => {
    return (
        <div>
            <Routes>
                <Route exact path="/" element={<ThemeOne />} />
                <Route exact path="/degen-donkeys" element={<DegenDonkeys />} />
                <Route exact path="/scrappies" element={<ScrapMonsters />} />
                <Route exact path="/coinflip" element={<CoinFlip />} />
                <Route exact path="/discord/auth/callback" element={<Callback />} />
                <Route exact path="/discord/unlink" element={<UnlinkDiscord />} />
                <Route exact path="/discord/link" element={<DiscordConnect />} />
                <Route path="*" element={<ThemeOne />} />
            </Routes>
        </div>
    );
}

export default MyRouts;
