import React, { useState, useEffect } from 'react';
import RandomMessage from '../common/getMotds';

const initData = {
  sub_heading: 'Timmy Finance',
  heading: 'Howdy'
};

const flattenMessages = (messages) => {
  const flattened = [];

  const flattenRecursively = (arr) => {
    if (Array.isArray(arr)) {
      arr.forEach((item) => flattenRecursively(item));
    } else {
      flattened.push(arr);
    }
  };

  flattenRecursively(messages);

  return flattened;
};


const Hero = () => {
  const rawMessages = RandomMessage();
  const messages = flattenMessages(rawMessages);

  const [messageIndex, setMessageIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const typingSpeed = 100;
    const deletingSpeed = 100;
    const currentMessage = messages[messageIndex];
    const timer = setTimeout(() => {
      if (!isDeleting && displayText.length === currentMessage.length) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && displayText === '') {
        setIsDeleting(false);
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      } else {
        setDisplayText((prevText) =>
            isDeleting
                ? prevText.slice(0, prevText.length - 1)
                : prevText + currentMessage[prevText.length]
        );
      }
    }, isDeleting ? deletingSpeed : typingSpeed);

    return () => clearTimeout(timer);
  }, [displayText, isDeleting, messageIndex, messages]);

  return (
      <>
        <style>
          {`
          .animated-text {
            border-right: 0.2em solid #666;
            white-space: nowrap;
            overflow: hidden;
            animation: typingCursor 0.5s infinite;
          }

          @keyframes typingCursor {
            0% { border-color: transparent; }
            50% { border-color: #666; }
            100% { border-color: transparent; }
          }

          .hero-section {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            padding: 20px;
          }

          .intro-text {
            font-size: 2em;
            font-weight: bold;
            color: white;  /* Making the "ROO Finance" wording white */
          }

          @media (max-width: 768px) {
            .intro-text {
              font-size: 1.5em;
            }

            h1 {
              font-size: 1.2em;
            }

            .animated-text {
              font-size: 1em;
            }
          }
        `}
        </style>
        <section className="hero-section">
          <div className="container text-center">
            <span className="intro-text">{initData.sub_heading}</span>
            <h1 className="mt-4">{initData.heading}</h1>
            <h2 className="animated-text">{displayText}</h2>
          </div>
        </section>
      </>
  );
};

export default Hero;
